import logo from './logo.svg';
import './App.css';
//import IndexView from './pages/simple_with_offset_screenshot_hero';
//import IndexView from './pages/illustrated_hero_with_screenshot_section';
//import IndexView from './pages/background_image_hero_with_alternating_features'; // using this as a base
import IndexView from './pages/IndexView';
import RequestDemoModal from './components/modals/RequestDemoModal';
import ModalContextProvider from './context_providers/ModalContextProvider';
import FormSubmittedNotification from './components/notifications/FormSubmittedNotification';

function App() {
  return (
    <div>
      <ModalContextProvider>
	      <IndexView/>
        <RequestDemoModal/>
        <FormSubmittedNotification/>
      </ModalContextProvider>
    </div>
  );
}

export default App;
