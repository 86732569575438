import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const plugins = {
  dvr: dvr({ package: validatorjs }),
};

const fields = [{
    name: 'companyName',
    label: 'Company name (optional)',
    placeholder: 'Enter your company name',
    rules: 'required|string',
  }, {
    name: 'industry',
    label: 'Industry',
    placeholder: 'Select your industry',
    rules: 'required|string',
    options: [
        'Accounting',
        'Advertising',
        'Aerospace',
        'Agriculture',
        'Automotive',
        'Banking',
        'Biotechnology',
        'Chemical',
        'Construction',
        'Consulting',
        'Consumer Goods',
        'Education',
        'Energy',
        'Engineering',
        'Entertainment',
        'Environmental',
        'Fashion',
        'Finance',
        'Food & Beverage',
        'Government',
        'Healthcare',
        'Hospitality',
        'Insurance',
        'Manufacturing',
        'Media',
        'Mining',
        'Nonprofit',
        'Oil & Gas',
        'Pharmaceutical',
        'Real Estate',
        'Retail',
        'Technology',
        'Telecommunications',
        'Transportation',
        'Utilities',
        'Other' // Include 'Other' option at the bottom
    ]
  }, {
    name: 'contactName',
    label: 'Name',
    placeholder: 'Enter your full name',
    rules: 'required|string',
  }, {
    name: 'email',
    label: 'Email',
    placeholder: 'Enter your email address',
    rules: 'required|email|string',
  }, {
    name: 'phone',
    label: 'Phone number',
    placeholder: 'Enter your phone number',
    rules: 'required|string',
  }, {
    name: 'website',
    label: 'Company website (optional)',
    placeholder: 'Enter your company website',
    rules: 'url|string',
  }, {
    name: 'subscribeToNewsletter',
    label: 'Subscribe',
    value: false, // Initial value for the checkbox
    type: 'checkbox'
  }];

  /*
  const hooks = {
    onSuccess(form) {
      alert('Form is valid! Send the request here.');
      // get field values
      console.log('Form Values!', form.values());
    },
    onError(form) {
      alert('Form has errors!');
      // get all form errors
      console.log('All form errors', form.errors());
    }
  }*/

  const form = new MobxReactForm({ fields }, { plugins });

  export default form;