import React from 'react';
import { observer } from 'mobx-react';

const RequestDemoForm = ({form, onSuccess, onError, loading}) => {
    /*
    const onSuccess = (form) => {
        console.log('onSuccess')
        
        const { username, email, password, passwordConfirm, ...other } = form.values(); // unpack form.values

        try {
            const response = await Login(form.values.email, form.values.password);
            // Handle successful login response here
        } catch (error) {
            // Handle login error here
            console.log('error caught')
            console.log(error)
        }

        form.clear()
        
    }
    

    const onError = (form) => {
        console.log('onError')
        console.log(form)
    }
    */

    const handleSubmit = (e) => {
        console.log("handleSubmit")
        e.preventDefault()
        form.onSubmit(e, {onSuccess: onSuccess, onError: onError})
    }

    return (
        <form noValidate onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
            <h2 className="text-2xl font-bold leading-tight text-primary text-gray-700 text-center">Request demo</h2>
            <div>
                <label htmlFor={form.$('contactName').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('contactName').label}
                </label>
                <div className="mt-1">
                    <input
                        {...form.$('contactName').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('contactName').error ? 'visible' : 'hidden' }}>
                    {form.$('contactName').error}
                </p>
            </div>

            <div>
                <label htmlFor={form.$('email').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('email').label}
                </label>
                <div className="mt-1">
                    <input
                        {...form.$('email').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('email').error ? 'visible' : 'hidden' }}>
                    {form.$('email').error}
                </p>
            </div>

            <div>
                <label htmlFor={form.$('phone').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('phone').label}
                </label>
                <div className="mt-1">
                    <input
                        {...form.$('phone').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('phone').error ? 'visible' : 'hidden' }}>
                    {form.$('phone').error}
                </p>
            </div>

            <div>
                <label htmlFor={form.$('industry').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('industry').label}
                </label>
                <div className="mt-1">
                    <select
                        {...form.$('industry').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        {form.$('industry').options.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('industry').error ? 'visible' : 'hidden' }}>
                    {form.$('industry').error}
                </p>
            </div>

            <div>
                <label htmlFor={form.$('website').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('website').label}
                </label>
                <div className="mt-1">
                    <input
                        {...form.$('website').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('website').error ? 'visible' : 'hidden' }}>
                    {form.$('website').error}
                </p>
            </div>

            <div>
                <label htmlFor={form.$('companyName').id} className="block text-sm font-medium leading-6 text-gray-900">
                    {form.$('companyName').label}
                </label>
                <div className="mt-1">
                    <input
                        {...form.$('companyName').bind()}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <p className="text-red-600 text-xs mt-1" style={{ visibility: form.$('companyName').error ? 'visible' : 'hidden' }}>
                    {form.$('companyName').error}
                </p>
            </div>

            <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                    <input
                        type="checkbox"
                        id={form.$('subscribeToNewsletter').id}
                        {...form.$('subscribeToNewsletter').bind()}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                </div>
                <div className="text-sm leading-6">
                    <label htmlFor={form.$('subscribeToNewsletter').id} className="font-medium text-gray-900">
                        {form.$('subscribeToNewsletter').label}
                    </label>
                    <p className="text-gray-500">Subscribe to the newsletter and receive updates.</p>
                </div>
            </div>

            <button
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                type="submit"
                disabled={loading}
            >
                {loading ? 'Submitting...' : 'Submit'}
            </button>

            <p className="text-red-600 text-xs mt-1">{form.error}</p>
    </form>
    )
};

export default observer(RequestDemoForm);