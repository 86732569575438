import { useState } from 'react';
import ModalContext from './ModalContext';

const ModalContextProvider = ({ children }) => {
  const [isRequestDemoModalOpen, setRequestDemoModalOpen] = useState(false);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');

  return (
    <ModalContext.Provider
      value={{
        isRequestDemoModalOpen,
        setRequestDemoModalOpen,
        isNotificationOpen,
        setNotificationOpen,
        customerEmail,
        setCustomerEmail
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;