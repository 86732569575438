//import { observer } from 'mobx-react';
import { Fragment, useRef, useState, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ModalContext from '../../context_providers/ModalContext';
import RequestDemoForm from '../../forms/RequestDemoForm';
import RequestDemoFormManager from "../../forms/RequestDemoFormManager";
import axios from 'axios';
import form from '../../forms/RequestDemoFormManager';

//import { CreateAPIKey } from 'api/keys';
//import CreateAPIKeyForm from 'forms/create_api_key_form/CreateAPIKeyForm'
//import ToastError from 'components_reusable/ToastError';
//import ToastSuccess from 'components_reusable/ToastSuccess';
//import { TOAST_MESSAGE_SUCCESSFULLY_CREATED_API_KEY } from 'utils/constants';
//import KeyStore from 'stores/ApiKeyStore';

const SUBMISSION_URL = 'https://g3tlsnxzwj.execute-api.us-east-1.amazonaws.com/dev/testing';

const RequestDemoModal = () => {
    const cancelButtonRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { 
        isRequestDemoModalOpen,
        setRequestDemoModalOpen,
        setNotificationOpen,
        setCustomerEmail
      } = useContext(ModalContext);

    const PostFormData = async (formData) => {
        try {
            setLoading(true);
            const options = {
                'Content-Type': 'application/json',
            }
            const response = await axios.post(SUBMISSION_URL, formData, options);
            console.log('Form submission successful:', response);
            setLoading(false);
            setRequestDemoModalOpen(false);
            setCustomerEmail(formData.email);
            setNotificationOpen(true);
            form.clear();
          } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
          }
    }

    const onSuccess = (form) => {
        const {
            contactName,
            email,
            phone,
            industry,
            website,
            companyName,
            subscribeToNewsletter
        } = form.values();

        PostFormData({contactName, email, phone, industry, website, companyName, subscribeToNewsletter});
    }

    const onError = (form) => {
        console.debug('onError')
        setLoading(false);
    }

    return (
        <Transition.Root show={isRequestDemoModalOpen} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={ () => setRequestDemoModalOpen(false) }>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <RequestDemoForm loading={loading} onSuccess={onSuccess} onError={onError} form={RequestDemoFormManager}/>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default RequestDemoModal;
